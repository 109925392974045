.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  background: var(--colour_primary);
  color: var(--colour_background);
  height: 100%;
  width: 300px;
  position: fixed;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  /* position: sticky; */
  /* top: 0; */
  z-index: 999;
}

.navbar-container {
  /* display: flex; */
  justify-content: center;
  align-items: left;
  /* height: 80px; */
  max-width: 90%;
}

.navbar-logo {
  color: var(--colour_background);
  /* justify-self: start; */
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  padding-top: 2rem;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  /* display: block; */
  /* grid-template-rows: repeat(4, auto); */
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100%;
  padding-left: 15%;
  /* justify-content: end; */
  /* margin-right: 2rem; */
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: var(--colour_background);
  display: flex;
  align-items: center;
  text-decoration: none;
  /* padding: 0.5rem 1rem; */
  height: 100%;
  font-size: x-large;
}

.nav-links:hover {
  background-color: var(--colour_background);
  color: var(--colour_primary);
  transition: all 0.2s ease-out;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar {
    position: relative;
    position: fixed;
    left: 0;
    top: 0;
    width: 95%;
    height: 80px;
    padding-right: 5%;
  }

  .navbar-container {
    height: 80px;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 60px;
    left: -200%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: var(--colour_background);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: start;
    color: var(--colour_primary);
    /* padding: 2rem; */
    width: 100%;
    /* display: table; */
  }

  .nav-links:hover {
    background-color: var(--colour_primary);
    color: var(--colour_background);
    /* border-radius: 0; */
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
    padding-top: 0rem;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-150%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--colour_background);
    font-size: 2rem;
  }

  .fa-bars {
    color: var(--colour_background);
    left: 7px;
    top: -2px;
    position: relative;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    /* color: var(--colour_background); */
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    /* background: white; */
    /* color: #242424; */
    transition: 250ms;
  }
}
