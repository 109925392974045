.home-container {
  height: 100%;
  padding-top: 0.01rem;
  /* background-color: aqua; */
}
.home-title-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  /* height: 1000px; */
  align-items: flex-start;
  justify-content: center;
  /* height: 100%; */
}

.home-fulltitle {
  text-align: left;
  margin-right: 2rem;
  max-width: 400px;

  /* font-family: "Montserrat"; */
}

.home-title {
  font-size: 5rem;
  margin-bottom: -2rem;
  margin-left: -0.4rem;
  /* font-weight: bolder; */
  letter-spacing: -0.2rem;
  
}

.home-logo {
  /* min-width: 100px; */
  margin-top: 30%;
  width: 100%;
  /* min-height: 100px; */
  height: 100%;
  /* filter: invert(15%) sepia(55%) saturate(512%) hue-rotate(161deg)
    brightness(97%) contrast(96%); */

  /* background-color: lightpink; */
}

.home-image {
  min-width: 250px;
  max-width: 100%;
  /* min-height: 550px; */
  /* height: 750px; */
  /* background-color: lightsteelblue; */
}

@media screen and (max-width: 1042px) {
  .home-container {
    margin-top: 80px;
  }

  .home-logo {
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .home-title-container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .home-fulltitle {
    margin-top: -4rem;
    margin-right: 0;
    text-align: center;
  }
  .home-title {
    font-size: 5rem;
    margin-bottom: -2rem;
    margin-left: 0rem;
  }
}

/* @media screen and (max-width: 1042px) {
  .home-logo {
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .home-title-container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .home-fulltitle {
    margin-top: -4rem;
    text-align: center;
  }
} */
