.contactus-container {
  /* display: flex; */
  /* flex-wrap: wrap-reverse; */
  padding-top: 3rem;
}

.contactus-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 10%;
}
.contactus-title {
  text-align: center;
}

.contactus-image {
  padding-left: 2rem;
  width: 150px;
  height: 150px;
}

/* .contactus-address-container {
  display: flex;
} */
.contactus-text {
  text-align: left;
  /* font-size: large; */
  margin-left: 2rem;
  font-style: normal;
}

.contactus-name {
  font-size: larger;
  margin-top: 0;
}
.contactus-address {
  font-size: large;
  margin-top: -1.5rem;
}
