.teamcard-container {
  width: 250px;
}
.teamcard-summary {
  text-align: justify;
}

.teamcard-image {
  width: 200px;
}
