.market-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: left; */
  font-size: medium;
}

.market-title {
  text-align: left;
  /* padding: 1rem; */
  /* max-width: 50%; */
}

.market-image-block {
  padding: 1rem;
  /* align-content: center; */
  /* justify-content: center; */
}
.market-list {
  font-size: larger;
}
.market-list-item {
  margin-top: 0.5rem;
}
.market-image {
  /* margin-left: 50%; */
  /* background-color: var(--colour_secondary); */
  /* color: var(--colour_background); */
  /* padding-top: 2rem; */
  /* align-content: center; */
  /* justify-content: center; */
  /* height: 200px; */
  min-height: 10%;
  /* width: 800px; */
  width: 100%;
}

@media screen and (max-width: 1042px) {
}
