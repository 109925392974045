.App {
  text-align: center;
  display: grid;

  grid-template-columns:
    [LWS_Start] minmax(0px, auto) [LWS_End] 300px [Sidebar-Content] minmax(
      500px,
      1000px
    )
    [RWS_Start] minmax(0, auto) [RWS_End];
}

.Content {
  grid-column-start: 3;
  grid-column-end: 4;
  /* background: lightgray; */
  padding: 2rem;
}

.Sidebar {
  grid-column-start: 2;
  grid-column-end: 3;
  position: fixed;
}

.Footer {
  grid-column-start: 3;
  grid-column-end: 4;
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  color: var(--colour_background);
  background-color: var(--colour_primary);
  /* color: white; */
}

@media screen and (max-width: 960px) {
  .App {
    text-align: center;
    display: block;
  }
  .Content {
    display: block;
  }
  .Sidebar {
    position: unset;
  }
}

/* @media screen and (min-width: 2000px) {
  .Window {
    text-align: center;
    display: block;
    grid-template-columns: [first] auto [navbarS] 200px [navbarE] 1500px [end];
  }
} */
