@import url(https://fonts.googleapis.com/css?family=Inter);
/* @import url("https://fonts.googleapis.com/css?family=Montserrat"); */
/* @import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css?family=Commissioner");
@import url("https://fonts.googleapis.com/css?family=Encode+Sans");
@import url("https://fonts.googleapis.com/css?family=Manrope");
@import url("https://fonts.googleapis.com/css?family=Mulish");
@import url("https://fonts.googleapis.com/css?family=Outfit");
@import url("https://fonts.googleapis.com/css?family=Work+Sans"); */

:root {
  --colour_primary: #003078;
  /* --colour_primary: #173042; */
  /* filter: invert(15%) sepia(55%) saturate(512%) hue-rotate(161deg) brightness(97%) contrast(96%); */
  /* --colour_secondary: #236477; */
  /* filter: invert(31%) sepia(29%) saturate(1013%) hue-rotate(147deg) brightness(96%) contrast(87%); */
  /* --colour_tertiary: #cfdfda; */
  /* filter: invert(97%) sepia(11%) saturate(239%) hue-rotate(95deg) brightness(94%) contrast(86%); */
  /* --colour_quarternary: #7cad3e; */
  /* filter: invert(55%) sepia(68%) saturate(379%) hue-rotate(44deg) brightness(98%) contrast(89%); */
  /* --colour_quinary: #4a6c2f; */
  /* filter: invert(36%) sepia(23%) saturate(967%) hue-rotate(50deg) brightness(96%) contrast(88%); */

  --colour_background: white;
  /* --colour_background: var(--colour_tertiary); */
}

body {
  margin: 0;

  /* Soft, pending colour */
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  /* font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  /* color: rgb(54, 54, 54); */
  background-color: white;
  background-color: var(--colour_background);
  color: #003078;
  color: var(--colour_primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.6rem;
}

a {
  color: #003078;
  color: var(--colour_primary);
}

.App {
  text-align: center;
  display: grid;

  grid-template-columns:
    [LWS_Start] minmax(0px, auto) [LWS_End] 300px [Sidebar-Content] minmax(
      500px,
      1000px
    )
    [RWS_Start] minmax(0, auto) [RWS_End];
}

.Content {
  grid-column-start: 3;
  grid-column-end: 4;
  /* background: lightgray; */
  padding: 2rem;
}

.Sidebar {
  grid-column-start: 2;
  grid-column-end: 3;
  position: fixed;
}

.Footer {
  grid-column-start: 3;
  grid-column-end: 4;
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  color: var(--colour_background);
  background-color: var(--colour_primary);
  /* color: white; */
}

@media screen and (max-width: 960px) {
  .App {
    text-align: center;
    display: block;
  }
  .Content {
    display: block;
  }
  .Sidebar {
    position: unset;
  }
}

/* @media screen and (min-width: 2000px) {
  .Window {
    text-align: center;
    display: block;
    grid-template-columns: [first] auto [navbarS] 200px [navbarE] 1500px [end];
  }
} */

:root {
  --primary: white;
  --inversePrimary: black;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: white;
  background-color: var(--primary);
  color: black;
  color: var(--inversePrimary);
  border: 1px solid white;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: white;
  padding: 8px 20px;
  border: 1px solid white;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
  background: white;
  color: black;
  transition: all 0.3s ease-out;
}

.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  background: var(--colour_primary);
  color: var(--colour_background);
  height: 100%;
  width: 300px;
  position: fixed;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  /* position: sticky; */
  /* top: 0; */
  z-index: 999;
}

.navbar-container {
  /* display: flex; */
  justify-content: center;
  align-items: left;
  /* height: 80px; */
  max-width: 90%;
}

.navbar-logo {
  color: var(--colour_background);
  /* justify-self: start; */
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  padding-top: 2rem;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  /* display: block; */
  /* grid-template-rows: repeat(4, auto); */
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100%;
  padding-left: 15%;
  /* justify-content: end; */
  /* margin-right: 2rem; */
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: var(--colour_background);
  display: flex;
  align-items: center;
  text-decoration: none;
  /* padding: 0.5rem 1rem; */
  height: 100%;
  font-size: x-large;
}

.nav-links:hover {
  background-color: var(--colour_background);
  color: var(--colour_primary);
  transition: all 0.2s ease-out;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar {
    position: relative;
    position: fixed;
    left: 0;
    top: 0;
    width: 95%;
    height: 80px;
    padding-right: 5%;
  }

  .navbar-container {
    height: 80px;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 60px;
    left: -200%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: var(--colour_background);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: start;
    color: var(--colour_primary);
    /* padding: 2rem; */
    width: 100%;
    /* display: table; */
  }

  .nav-links:hover {
    background-color: var(--colour_primary);
    color: var(--colour_background);
    /* border-radius: 0; */
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
    padding-top: 0rem;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-150%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--colour_background);
    font-size: 2rem;
  }

  .fa-bars {
    color: var(--colour_background);
    left: 7px;
    top: -2px;
    position: relative;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    /* color: var(--colour_background); */
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    /* background: white; */
    /* color: #242424; */
    transition: 250ms;
  }
}

.home-container {
  height: 100%;
  padding-top: 0.01rem;
  /* background-color: aqua; */
}
.home-title-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  /* height: 1000px; */
  align-items: flex-start;
  justify-content: center;
  /* height: 100%; */
}

.home-fulltitle {
  text-align: left;
  margin-right: 2rem;
  max-width: 400px;

  /* font-family: "Montserrat"; */
}

.home-title {
  font-size: 5rem;
  margin-bottom: -2rem;
  margin-left: -0.4rem;
  /* font-weight: bolder; */
  letter-spacing: -0.2rem;
  
}

.home-logo {
  /* min-width: 100px; */
  margin-top: 30%;
  width: 100%;
  /* min-height: 100px; */
  height: 100%;
  /* filter: invert(15%) sepia(55%) saturate(512%) hue-rotate(161deg)
    brightness(97%) contrast(96%); */

  /* background-color: lightpink; */
}

.home-image {
  min-width: 250px;
  max-width: 100%;
  /* min-height: 550px; */
  /* height: 750px; */
  /* background-color: lightsteelblue; */
}

@media screen and (max-width: 1042px) {
  .home-container {
    margin-top: 80px;
  }

  .home-logo {
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .home-title-container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .home-fulltitle {
    margin-top: -4rem;
    margin-right: 0;
    text-align: center;
  }
  .home-title {
    font-size: 5rem;
    margin-bottom: -2rem;
    margin-left: 0rem;
  }
}

/* @media screen and (max-width: 1042px) {
  .home-logo {
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .home-title-container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .home-fulltitle {
    margin-top: -4rem;
    text-align: center;
  }
} */

.benefitscard-container {
  width: 400px;
  text-align: center;
  /* background-color: var(--colour_primary); */
  /* background-color: white; */
  color: var(--colour_primary);
  border: solid;
  border-color: var(--colour_primary);
  /* color: var(--colour_background); */
  padding: 25px;
}
.benefitscard-modal {
  width: 50%;
  text-align: justify;
}
.benefitscard-image {
  /* width: 250px; */
  height: 250px;
  /* background-color: var(--colour_background); */
  padding: 2rem;
}

.benefits-container {
  padding-top: 0.01rem;
}
.benefits-content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  /* background-color: var(--colour_quinary); */
}
/* .benefits-title {
  font-size: 3rem;
} */

.teamcard-container {
  width: 250px;
}
.teamcard-summary {
  text-align: justify;
}

.teamcard-image {
  width: 200px;
}

.team-container {
  padding-top: 0.01rem;
}
.team-content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  background-color: var(--colour_primary);
  color: var(--colour_background);
  padding-top: 2rem;
}

.tech-container {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  background-color: var(--colour_primary);
  color: var(--colour_background);
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.tech-copy {
  text-align: left;
  padding: 1rem;
  padding-bottom: 0rem;
  max-width: 50%;
}

.tech-title {
  margin-left: 1rem;
}

.tech-image {
  /* padding-right: 1rem; */
  width: 330px;
  padding-bottom: 1rem;
  margin-left: 1rem;
  margin-top: 25%;
}
.tech-list {
  font-size: large;
}
.tech-list-item {
  margin-top: 0.5rem;
}

@media screen and (max-width: 1042px) {
  .tech-title {
    margin-left: 0;
  }
  .tech-copy {
    max-width: 100%;
  }
  .tech-image {
    max-width: 90%;
    margin: 1rem;
  }
}

.market-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: left; */
  font-size: medium;
}

.market-title {
  text-align: left;
  /* padding: 1rem; */
  /* max-width: 50%; */
}

.market-image-block {
  padding: 1rem;
  /* align-content: center; */
  /* justify-content: center; */
}
.market-list {
  font-size: larger;
}
.market-list-item {
  margin-top: 0.5rem;
}
.market-image {
  /* margin-left: 50%; */
  /* background-color: var(--colour_secondary); */
  /* color: var(--colour_background); */
  /* padding-top: 2rem; */
  /* align-content: center; */
  /* justify-content: center; */
  /* height: 200px; */
  min-height: 10%;
  /* width: 800px; */
  width: 100%;
}

@media screen and (max-width: 1042px) {
}

.contactus-container {
  /* display: flex; */
  /* flex-wrap: wrap-reverse; */
  padding-top: 3rem;
}

.contactus-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 10%;
}
.contactus-title {
  text-align: center;
}

.contactus-image {
  padding-left: 2rem;
  width: 150px;
  height: 150px;
}

/* .contactus-address-container {
  display: flex;
} */
.contactus-text {
  text-align: left;
  /* font-size: large; */
  margin-left: 2rem;
  font-style: normal;
}

.contactus-name {
  font-size: larger;
  margin-top: 0;
}
.contactus-address {
  font-size: large;
  margin-top: -1.5rem;
}

.footer {
  background-color: var(--colour_primary);
  color: var(--colour_background);
  width: 100%;

  /* position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100; */
}

